import styled from "styled-components";

export const StyledServiceCard = styled.div`
    gap: 25px;
   justify-content: center;
display: flex;
flex-direction: column;
align-items: center;
height: 200px;
/* width: 400px; */
width: 100%;
border-radius: 15px;
border: 1px solid ${(props)=>props.theme.white.serviceCardBorder};
background:${(props)=>props.theme.white.serviceCardBackground};
box-shadow: -1px 1px 2px 0px rgba(71, 109, 133, 0.10), -3px 3px 4px 0px rgba(71, 109, 133, 0.09), -7px 7px 6px 0px rgba(71, 109, 133, 0.05), -13px 13px 7px 0px rgba(71, 109, 133, 0.01), -20px 20px 8px 0px rgba(71, 109, 133, 0.00);
.image{
    width: 40px;
    height: 40px;
    img{
        width: 100%;
        height: 100%;
    }
}
p{
    color: ${(props)=>props.theme.blue.headBlue};

font-family: Exo;
font-size: 20px;
font-style: normal;
font-weight: 700;
}
`