import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL}),
    endpoints: (builder) => ({
        sendFormData: builder.mutation({
            query: (data) => {
              return {
                url: `submit`,
                method: "POST",
                body: data,
              };
            },
          }),
          uploadImage: builder.mutation({
            query: (file) => {
              return {
                url: `upload`,
                method: "POST",
                body: file,
              };
            },
          }),
          
    }),
  });
  export const { useSendFormDataMutation, useUploadImageMutation} = authApi;