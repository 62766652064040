export const theme = {
    dark: {
      blue:{
        navyBlue: "#5F6E7A",
        lightBlue: "#275DFD",
        headBlue: "white",
        tagPara: "#285EF9",
        servicePara: "white"
      },
      black: "white",
      white:{
        plainWhite: "#1B1A28",
        fadeWhite: "#EBEDEC",
        serviceCardBackground: "#232236",
        serviceCardBorder: "#373741"
      },
      grey: "white",
      backgroundTrust: "rgba(47, 45, 74, 0.40)",
    },
    light: {
      blue:{
        navyBlue: "#363C4F",
        lightBlue: "#275DFD",
        headBlue: "#394955",
        tagPara: "#285EF9",
        servicePara: "#363C4F",
      },
      black: "#000",
      white:{
        plainWhite: "white",
        fadeWhite: "#EBEDEC",
        serviceCardBackground: "white",
        serviceCardBorder: "#D9E3E9"
      },
      grey: "rgba(57, 73, 85, 0.80)",
      backgroundTrust: "rgba(246, 244, 235, 0.70)",
    },
  };