import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cmsApi = createApi({
    reducerPath: "cmsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "https://nebula-cms-9b5d81ea0a0f.herokuapp.com/api" }),
    endpoints: (builder) => ({
      getCmsLandingData: builder.query({
        query: () => {
          return {
            url: `/landing-page?populate=*`,
            method: "GET",
          };
        },
      }),
      getCmsAboutData: builder.query({
        query: () => {
          return {
            url: `/about-page?populate=*`,
            method: "GET",
          };
        },
      }),
      getCmsClientsData: builder.query({
        query: () => {
          return {
            url: `/clients-page?populate=*`,
            method: "GET",
          };
        },
      }),
      getCmsServicesData: builder.query({
        query: () => {
          return {
            url: `/services-page`,
            method: "GET",
          };
        },
      }),
      getCmsFooterData: builder.query({
        query: () => {
          return {
            url: `/footer`,
            method: "GET",
          };
        },
      }),
    }),
  });
  
  export const { useGetCmsLandingDataQuery, useGetCmsAboutDataQuery, useGetCmsClientsDataQuery, useGetCmsServicesDataQuery, useGetCmsFooterDataQuery } = cmsApi;