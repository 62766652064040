import Navbar from "../../Components/Navbar/Navbar";
import { BackgroundContainer, GlobalContainer } from "../../GlobalStyles";
import { StyledContactContainer } from "./Contact.styles";
import LowerFooter from "../../Components/LowerFooter/LowerFooter";
import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import PrevWorkCard from "./Components/PrevWorkCard/PrevWorkCard";
import Footer from "../../Components/Footer/Footer";

const Contact = () => {
  return (
    <BackgroundContainer>
      <Navbar />
      <GlobalContainer>
        <StyledContactContainer>
          <div className="form-container">
            <p className="title">Start Your Project Today!</p>
            <p className="desc">Fill in this form to get in contact with me.</p>
            <Form>
              <div className="form-row-container">
                <div className="field-container">
                  <label>Full Name</label>
                  <Form.Item>
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>
                </div>
                <div className="field-container">
                  <label>Email Address</label>
                  <Form.Item>
                    <Input placeholder="Enter email address" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-container">
                <div className="field-container full">
                  <label>Contact Information</label>
                  <Form.Item>
                    <Input placeholder="Other ways of contact(Discord id, Twitter etc)" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-container">
                <div className="field-container full">
                  <label>Services</label>
                  <Form.Item>
                    <Input placeholder="Service/s Your Are Interested In" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-container">
                <div className="field-container full">
                  <label>Project Information</label>
                  <Form.Item>
                    <TextArea placeholder="Enter Information About Your Project" />
                  </Form.Item>
                </div>
              </div>
              <Button className="save-btn">Send</Button>
            </Form>
          </div>
          <div className="right">
            <div className="card">
              <p className="title">Check out some of my previous work!</p>
              <div className="prev-work-container">
                <PrevWorkCard />
                <PrevWorkCard />
                <PrevWorkCard />
                <PrevWorkCard />
                <Button className="view-all-btn">View All</Button>
              </div>
            </div>
          </div>
        </StyledContactContainer>
      </GlobalContainer>
      <div>
        <Footer />
        <LowerFooter />
      </div>
      </BackgroundContainer>

  );
};

export default Contact;
