import styled from "styled-components";

export const StyledServices = styled.div`
display: flex;
flex-direction: column;
gap: 100px;
padding-top: 100px;
justify-content: center;
background: ${(props) => props.theme.white.plainWhite};
h3{
    color: ${(props)=>props.theme.blue.headBlue};

font-family: Exo;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 120.523%;
margin: 0;
}
.stepper-container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}p{
    color: ${(props)=>props.theme.grey};

font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}


`