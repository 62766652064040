import styled from "styled-components";

export const StyledHowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 200px;
  .head-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    span {
      color: ${(props) => props.theme.blue.headBlue};
      font-family: Exo;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
    }
    .offer {
      color: ${(props) => props.theme.blue.lightBlue}!important;
      font-size: 18px !important;
      font-weight: 500;
    }
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 140px;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 170px;
      .right {
        display: flex;
        flex-direction: column;
        gap: 40px;

        h2 {
          color: ${(props) => props.theme.blue.lightBlue};

          font-family: Exo;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
        p {
          color: ${(props) => props.theme.blue.headBlue};

          font-family: Exo;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
  @media(max-width: 1000px) {
  .container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

`;
