import React, { useContext, useState } from 'react'
import { StyledCustomDrawer } from './CustomDrawer.styles'
import { StyledButton, StyledNavlink } from '../Navbar/Navbar.styles'
import { ROUTES } from '../../Constants/Routes'
import { MdLightMode } from "react-icons/md";
import { FiMoon } from "react-icons/fi";
import { ThemeContext } from '../../App';
import { useTheme } from 'styled-components';
import AuditModal from '../AuditModal/AuditModal';

const CustomDrawer = ({openDrawer, setOpenDrawer}) => {
    const themeContext = useContext(ThemeContext);
    const [openModal, setOpenModal] = useState(false);
    const theme = useTheme();
    const onChange = () => {
        if (themeContext?.currentTheme === "light") {
          themeContext?.setCurrentTheme("dark");
          localStorage.setItem("currentTheme", "dark");
        } else {
          themeContext?.setCurrentTheme("light");
          localStorage.setItem("currentTheme", "light");
        }
      };
      const onClose = ()=>{
        setOpenDrawer(false);
      }
  return (
    <StyledCustomDrawer  title="Select Option"placement="right"  open={openDrawer} onClose={onClose}>
        <StyledNavlink to={ROUTES.HOME}>HOME</StyledNavlink>
          <StyledNavlink to={ROUTES.ABOUT}>ABOUT</StyledNavlink>
          <StyledNavlink to={ROUTES.CLIENTS}>CLIENTS</StyledNavlink>
          <StyledNavlink to={ROUTES.SERVICES}>SERVICES</StyledNavlink>
          <div
          style={{ cursor: "pointer" }}
          onClick={onChange}
        >
          {themeContext?.currentTheme === "light" ? (
            <FiMoon size={20} strokeWidth={1} color={theme.blue.navyBlue} />
          ) : (
            <MdLightMode size={20} color="white" />
          )}
        </div>
        <div className="audit-btn">

        <StyledButton onClick={()=>setOpenModal(true)}>Book an Audit</StyledButton>
        </div>
<AuditModal openModal={openModal} setOpenModal={setOpenModal} />



    </StyledCustomDrawer>
  )
}
export default CustomDrawer