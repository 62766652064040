import styled from "styled-components";

export const StyledPrevWorkCard = styled.div`
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #1137a7;
  /* padding: 5px 0 5px 5px; */
  width: 100%;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    p {
      margin: 0;
      text-align: left;
    }
    .right {
      .title {
        text-align: left !important;
        color: #fff;
        font-family: Exo;
        font-size: 18px;
        margin-bottom:5px;
        font-style: normal;
        font-weight: 700;
        line-height: 102.523%; /* 24.605px */
        letter-spacing: 0.48px;
      }
      .desc {
        text-align: left !important;
        color: #fff;
        font-family: Exo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 102.523%; /* 18.454px */
        letter-spacing: 0.36px;
      }
    }
  }
  .img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    img {
      height: 100%;
    }
  }
  
`;
