import React from 'react'
import { StyledClientCards } from './ClientCards.styles'

const ClientCards = ({url, clientOne, clientTwo, clientThree, clientFour}) => {
  return (
    <StyledClientCards>
        <img src={url}/>
        <p>{clientOne?.name || clientTwo?.name || clientThree?.name || clientFour?.name}</p>
        <span>{clientOne?.designation || clientTwo?.designation || clientThree?.designation || clientFour?.designation}</span>
    </StyledClientCards>
  )
}

export default ClientCards