import React from 'react'
import { StyledHowItWorks } from './HowItWorks.styles'
import WorkCard from './WorkCard/WorkCard'
import { StyledBorderButton } from '../../Home.styles'
import { useGetCmsLandingDataQuery } from '../../../../Services/Cms'

const HowItWorks = () => {
  const {data: getCmsLandingData} = useGetCmsLandingDataQuery();
  return (
    <StyledHowItWorks>
      <div className='head-container'>
      <span >How it works</span>
        <span className='offer'>based on field experience.</span>
      </div>
        
        <div className="cards-container">
          <div className='container'>
          <WorkCard cardOne ={getCmsLandingData?.data?.attributes?.WorkCards}/>
          <div className="right">
            <h2>01</h2>
            <p>{getCmsLandingData?.data?.attributes?.paraOne}</p>
              <div style={{width: "50%", textAlign:"left"}}>

            <StyledBorderButton>Learn more</StyledBorderButton>
            </div>

          </div>

          </div>
          <div className='container'>
            <div className="right">
            <h2>02</h2>
            <p>{getCmsLandingData?.data?.attributes?.paraTwo} </p>
              <div style={{width: "50%", textAlign:"left"}}>
            <StyledBorderButton>Learn more</StyledBorderButton>


              </div>
            </div>
            <WorkCard cardTwo={getCmsLandingData?.data?.attributes?.WorkCard2}/>
          </div>
            
        </div>

            
    </StyledHowItWorks>
  )
}

export default HowItWorks