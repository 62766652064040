import styled from "styled-components";

export const StyledStudiesCard = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
img{
    border-radius: 20px;
    width: 100%;
}
.desc{
    display: flex;
    justify-content: space-between;
    span{
        color: ${(props)=>props.theme.blue.headBlue};
font-family: Exo;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 102.523%; /* 24.605px */
letter-spacing: 0.48px;
    }
}
.eye{
    display: flex;
    gap: 8px;
    position: relative;

span{
    color: ${(props)=>props.theme.blue.headBlue};
font-family: Exo;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 102.523%; /* 24.605px */
letter-spacing: 0.48px;
}
.left-logo{
    position: absolute;
    bottom: 50px;
    transform: translate(-50%);
    width: 80%;
    margin: auto; 
    img{
        width: 100%;
    }
}
    

}

`