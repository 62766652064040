import styled from "styled-components";

export const StyledExperienceCards = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 500px;
height: 200px; */
  gap: 10px;
  align-items: center;
  border-radius: 40px;
  border: 2px solid rgba(57, 73, 85, 0.09);
  padding: 35px;
  background: ${(props) => props.theme.white.serviceCardBackground};

  .heading {
    display: flex;
    align-items: center;
    width: 100%;
    /* gap: 10px; */
    h2 {
      color: ${(props) => props.theme.blue.lightBlue};

      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 7.68px;
      margin: 0;
    }
  }
  .ant-divider-horizontal {
    min-width: 80% !important;
    width: 80% !important;
    background-color: #ced2d5;
    height: 3px !important;
  }
  p {
    color: ${(props) => props.theme.blue.navyBlue};
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 99%;
    margin: auto;
    line-height: 150%;
    text-align: left;
  }
  @media (max-width: 600px) {
    .ant-divider-horizontal {
      /* min-width: 0px !important;
      width: 0px !important; */
      display: none;
    }
  }
`;
