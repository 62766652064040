import React from "react";
import { StyledContactCard } from "./ContactCard.styles";
import { Button } from "antd";
import { ROUTES } from "../../../../Constants/Routes";
import { useNavigate } from "react-router";

const ContactCard = () => {
  const navigate = useNavigate();
  return (
    <StyledContactCard>
      <img style={{ width: "100%" }} src="/Images/Contacts/ContactCard.png" />
      <Button onClick={() => navigate(ROUTES.CONTACTS)} size="large">
        Contact me
      </Button>
    </StyledContactCard>
  );
};

export default ContactCard;
