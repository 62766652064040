import styled from "styled-components";

export const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white.plainWhite};

  gap: 100px;
  .tag-line-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    .left {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      padding-top: 100px;
      gap: 20px;
      h1 {
        color: ${(props) => props.theme.blue.headBlue};

        font-family: Exo;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
      }
      p {
        color: ${(props) => props.theme.blue.tagPara};

        font-family: Exo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
      }
    }
    .right {
      /* width: 400px; */
      width: 50%;
      margin: auto;
      display: flex;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .experience-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding-bottom: 170px;
    .experience-head {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      align-items: baseline;
      span {
        color: ${(props) => props.theme.blue.headBlue};
        font-family: Exo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }
      .offer {
        color: ${(props) => props.theme.blue.lightBlue}!important;
        font-size: 22px !important;
        font-weight: 500;
      }
    }
    .experience-cards {
      display: flex;
      /* flex-direction: column; */
      gap: 100px;
      position: relative;
      .field-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 100px;
      }
      .logo {
        position: absolute;
        top: 50%;
        left: 50% !important;
        width: 200px;
        transform: translate(-50%, -50%);
        height: 200px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .tag-line-container {
      
      
    }
    /* .logo {
      display: none;
    }
     */
  }
  @media (max-width: 800px) {
    .tag-line-container {
      grid-template-columns:repeat(1,1fr) ;
      
      
    }
    /* .logo {
      display: none;
    }
     */
  }
  @media (max-width: 600px) {
    .tag-line-container {

      
      .right {
        width: 90%;
        margin: auto;
      }
    }
    .logo {
      display: none;
    }
    .experience-cards{
      flex-direction: column !important;
    }
  }
`;
