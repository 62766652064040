import styled from "styled-components";

export const StyledContactCard = styled.div`
  width: 90%;
  margin: auto;
  border-radius: 40px;
  margin-bottom: 100px;
  position: relative;
  button {
    position: absolute;
    left: 50%;
    bottom: 20%;
    width: 250px;
    border-radius: 80px !important;
    height: 60px !important;
    transform: translate(-50%);
    span {
      color: #285ef9;
      font-family: Exo;
      font-size: 20px !important;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 4.8px;
    }
  }
  .left {
    display: flex;
    align-items: center;
    img {
      width: 80%;
      /* height: 80%; */
      margin: auto;
    }
  }
  .middle {
  }
  .right {
    width: 90%;
    margin: auto;
  }
 

@media(max-width: 1000px) {
 button{
  position: absolute;
  left: 50%;
  bottom: 20% !important;
  width: 28%;
  height: 40px !important;
  padding: 0 !important;
  span{
    font-size: 18px!important;
    letter-spacing: 2.5px;
  }
  
 }
}
@media(max-width: 650px) {
 button{
  position: absolute;
  left: 50%;
  bottom: 22px !important;
  width: 35%;
  height: 25px !important;
  padding: 0 !important;

  span{
    font-size: 14px!important;
    letter-spacing: 1.5px;
  }
  
 }
}
@media(max-width: 490px) {
  width: 100% !important;
 button{
  position: absolute;
  left: 50%;
  bottom: 16px !important;
  width: 35%;
  height: 35px !important;

  span{
    font-size: 10px!important;
    letter-spacing: 1px;
    padding-bottom: 10px;
    padding: 0px !important;
  }
  
 }
 .ant-btn{
  padding: 0px !important;
 }
}
`;
