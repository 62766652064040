import React from 'react'
import { StyledStudiesCard } from './StudiesCard.styles'

const StudiesCard = ({image,project, number}) => {
  return (
    <StyledStudiesCard>
        <img src={image} alt="" />
        <div className='desc'>
            <span>{project}</span>
            <div className='eye'>
                <img src="/Images/Clients/Eye.png"/>
                <span>{number}</span>
                <div className='left-logo'>
                  <img src="/Images/Clients/Left.png" alt="" />
                </div>

            </div>
        </div>
        
    </StyledStudiesCard>
  )
}

export default StudiesCard