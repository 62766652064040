import React from 'react'
import { StyledExperienceCards } from './ExperienceCards.styles'
import { Divider } from 'antd'

const ExperienceCards = ({cardOne, cardTwo, cardThree, cardFour}) => {
  return (
    <StyledExperienceCards>
        <div className="heading">
            <div style={{width: "20%"}}>
            <Divider/>

            </div>
            <h2>{cardOne?.cardHead || cardTwo?.cardHead || cardThree?.cardHead || cardFour?.cardHead}</h2>
        </div>
        <p>{cardOne?.cardPara || cardTwo?.cardPara || cardThree?.cardPara || cardFour?.cardPara}</p>

    </StyledExperienceCards>
  )
}

export default ExperienceCards