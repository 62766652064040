import React, { useContext } from 'react'
import { BackgroundContainer, GlobalContainer } from '../../GlobalStyles'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import LowerFooter from '../../Components/LowerFooter/LowerFooter'
import { StyledAbout } from './About.styles'
import ExperienceCards from './Components/ExperienceCards/ExperienceCards'
import { ThemeContext } from '../../App'
import { useGetCmsAboutDataQuery } from '../../Services/Cms'

const About = () => {
    const themeContext = useContext(ThemeContext);
    const {data: getCmsAboutData} = useGetCmsAboutDataQuery();
  return (
    <BackgroundContainer>

    <Navbar/>
    <GlobalContainer>
    <StyledAbout>
        <div className="tag-line-container">
            <div className="left">
            <h1>Tag Line</h1>
            <p>{getCmsAboutData?.data?.attributes?.Tagline}</p>

            </div>
            <div className="right">

                <img src="/Images/About/Frame.png"/>
            </div>
        </div>
        <div className="experience-container">
        <div className='experience-head'>
              <span>Experience</span>
              <span className='offer'>Previous endeavours.</span>
        </div>
        <div className="experience-cards">
            <div className='field-cards'>
            <ExperienceCards cardOne ={getCmsAboutData?.data?.attributes?.CardOne}/>
            <ExperienceCards cardTwo ={getCmsAboutData?.data?.attributes?.CardTwo}/>
            </div>
            <div className='logo'>
            {
        themeContext?.currentTheme === "light"? (<img src="/Images/About/Nebula.png" />): (<img src="/Images/About/NebulaDark.png" />)
      }
            </div>
            <div className='field-cards'>
            <ExperienceCards cardThree ={getCmsAboutData?.data?.attributes?.CardThree}/>
            <ExperienceCards cardFour={getCmsAboutData?.data?.attributes?.CardFour}/>
            </div>

        </div>

        </div>

    </StyledAbout>
    </GlobalContainer>
    <div>
        <Footer/>
        <LowerFooter/>
    </div>
    </BackgroundContainer>



  )
}

export default About