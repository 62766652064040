import { Divider } from 'antd'
import React from 'react'
import { StyledWorkCard } from './WorkCard.styles'
import { useGetCmsLandingDataQuery } from '../../../../../Services/Cms';

const WorkCard = ({cardOne, cardTwo}) => {
    const {data: getCmsLandingData} = useGetCmsLandingDataQuery();
  
  return (
    <StyledWorkCard>
        <div className="left-image">
            <img src="/Images/Work/Discord.png" alt="" />
        </div>
        <div className="right-image">
            <img src="/Images/Work/WorkCheck.png" alt="" />
        </div>
    <p className='welcome'>{cardOne?.hashHead || cardTwo?.hashHead }</p>
    <Divider/>
    <div className="para-container">
        <h3>{cardOne?.cardHead || cardTwo?.cardHead}</h3>
        <p>{cardOne?.cardDesc || cardTwo?.cardHead}</p>
        <div className="list-container">
            <div className="lists">
                <div style={{display: "flex", gap: "10px", alignItems:"center"}}>
                    <img src="/Images/Work/Work1.png" alt="" />
                    <span>{cardOne?.listOne || cardTwo?.listOne}</span>
                </div>
                <img src="/Images/Work/Tick.png" alt="" />
            </div>
            <div className="lists">
            <div style={{display: "flex", gap: "10px", alignItems:"center"}}>
                    <img src="/Images/Work/Work2.png" alt="" />
                    <span>{cardOne?.listTwo || cardTwo?.listTwo}</span>
                </div>
                <img src="/Images/Work/Tick.png" alt="" />
                
            </div>
            <div className="lists">
            <div style={{display: "flex", gap: "10px", alignItems:"center"}}>
                    <img src="/Images/Work/Work3.png" alt="" />
                    <span>{cardOne?.listThree || cardTwo?.listThree}</span>
                </div>
                <img src="/Images/Work/Tick.png" alt="" />
                
            </div>
        </div>

    </div>
    </StyledWorkCard>

    
  )
}

export default WorkCard;