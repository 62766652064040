import React from 'react'
import { StyledTrusted } from './Trusted.styles'
import Logos from './Logos/Logos'
import { useGetCmsLandingDataQuery } from '../../../../Services/Cms';

const Trusted = () => {
  const {data: getCmsLandingData} = useGetCmsLandingDataQuery();
  return (
    <StyledTrusted>
        <span>Trusted by</span>
        <p>{getCmsLandingData?.data?.attributes?.Trusted_by}</p>
        <Logos/>
    </StyledTrusted>
  )
}

export default Trusted