import React from "react";
import { StyledPrevWorkCard } from "./PrevWorkCard.styles";

function PrevWorkCard() {
  return (
    <StyledPrevWorkCard>
      <div className="container">
        <div className="img-container">
          <img src="/Images/Contacts/prevWork.svg" />
        </div>
        <div className="right">
          <p className="title">G2 Esports</p>
          <p className="desc">Discord Auditing & Setup</p>
        </div>
      </div>
    </StyledPrevWorkCard>
  );
}

export default PrevWorkCard;
