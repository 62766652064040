import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { cmsApi } from "./Services/Cms";
import { authApi } from "./Services/Auth";

const store = configureStore({
    reducer: {
      
      [cmsApi.reducerPath]: cmsApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
    
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(cmsApi.middleware).concat(authApi.middleware),
  });
  
  setupListeners(store.dispatch);
  
  export default store;