import styled from "styled-components";

export const StyledClients = styled.div`

display: flex;
flex-direction: column;
gap: 100px;
padding-top: 100px;
background: ${(props) => props.theme.white.plainWhite};
.clients-container{

    width: 60%;
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    h3{
        color: ${(props)=>props.theme.blue.headBlue};

font-family: Exo;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 120.523%;
margin: 0;

    }
    p{
        color: ${(props)=>props.theme.blue.tagPara};

text-align: center;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; 
margin: 0;
    }
    

}
.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    padding: 0 30px 0 30px;



}
.case-studies{
    display: flex;
    flex-direction: column;
    gap: 70px;
    .studies-head{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        align-items: baseline;
        span{
            color: ${(props)=>props.theme.blue.headBlue};
            font-family: Exo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            
        }
        .offer{
                color: ${(props)=>props.theme.blue.lightBlue}!important;
                font-size: 18px!important;
                font-weight: 500;
            }

    }
}
.studies-card-container{
    display: grid;
    grid-template-columns: repeat(3,1fr );
    gap: 50px;
    padding-bottom: 150px;

}
@media (max-width: 1000px) {
    .clients-container{
        width: 70%;
        margin: auto;
    }
    h3{
        text-align: center;
    }
    .studies-card-container{
    grid-template-columns: repeat(2,1fr );

}

}
@media (max-width: 600px) {
    .clients-container{
        width: 90%;
        margin: auto;
    }
    .studies-card-container{
    grid-template-columns: repeat(1,1fr );

}
}

`