import React from "react";
import { StyledServices } from "./Services.styles";
import { BackgroundContainer, GlobalContainer } from "../../GlobalStyles";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import LowerFooter from "../../Components/LowerFooter/LowerFooter";
import Stepper from "./Components/Stepper/Stepper";
import ContactCard from "./Components/ContactCard/ContactCard";
import { useGetCmsServicesDataQuery } from "../../Services/Cms";

const Services = () => {
  const {data: getCmsServicesData} = useGetCmsServicesDataQuery();
  return (
    <BackgroundContainer>

      <Navbar />
      <GlobalContainer>
        <StyledServices>
          <div className="stepper-container">
            <h3>Provided Services</h3>
            <Stepper />
            <p>
              {getCmsServicesData?.data?.attributes?.para}
            </p>
          </div>

          <ContactCard />
        </StyledServices>
      </GlobalContainer>
      <div>
        <Footer />
        <LowerFooter />
      </div>
      </BackgroundContainer>

  );
};

export default Services;
