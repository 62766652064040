import React from 'react'
import { StyledLogos } from './Logos.styles'

const Logos = () => {
  return (

    <StyledLogos>
        <marquee>
            <div className="marquee">
            <img src="/Images/Logo/Logo1.png"/>
        <img src="/Images/Logo/Logo2.png"/>
        <img src="/Images/Logo/Logo3.png"/>
        <img src="/Images/Logo/Logo4.png"/>

            </div>
        
        </marquee>
        
    </StyledLogos>
  )
}

export default Logos