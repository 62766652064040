import styled from "styled-components";

export const StyledTrusted = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.backgroundTrust};
  gap: 30px;
  padding: 20px;
  span {
    color: ${(props) => props.theme.blue.headBlue};
    font-family: Exo;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  p {
    width: 60%;
    margin: auto;
    color: ${(props) => props.theme.grey};
    text-align: center;
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;
