import { Steps } from "antd";
import styled from "styled-components";

export const StyledStepper = styled(Steps)`
  border-radius: 15px;
  border: 1px solid #275dfd;
  /* width: 60px; */
  background: rgba(39, 93, 253, 0.08);
  padding-left:50px;
  padding-right:50px;
  justify-content: space-between;
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 

  &::-webkit-scrollbar {
  display: none;
};

  .ant-steps-item {
    flex: none;
  }
  .ant-steps-item-title::after {
    /* color: pink !important; */
    width: 0px !important;
  }
  .ant-steps-item-title {
    color: #9A9EA9 !important;
    text-align: center;
    font-family: Exo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 102.523%;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .ant-steps-item-active{
    .ant-steps-item-title {

      color: ${(props)=>props.theme.blue.headBlue} !important;
    }
    .ant-steps-item-tail::after{
      width: 0px !important;
    } 
  }
  .ant-steps-item-container {
    display: flex;
    align-items: center;
    padding: 28px 10px 22px 10px;
    /* width: 10px; */
    width: 100%;
    .ant-steps-item-icon {
      display: flex;
      align-items: center;
    }
  }
  @media(max-width: 600px) {
    .ant-steps-item-title{
      font-size: 16px!important;
      align-items: center !important;
      margin-top: 10px;
    }
 
}
`;
