import React, { useContext } from 'react'
import { StyledLowerFooter } from './LowerFooter.styles'
import { ThemeContext } from '../../App'

const LowerFooter = () => {
  const { currentTheme} = useContext(ThemeContext);
  return (
    <StyledLowerFooter>
      {
        currentTheme ==="light"?(<img src="/Images/CzLabs.png" alt="" />):(<img src="/Images/CzLabsDark.png" alt="" />)
      }
        
    </StyledLowerFooter>
  )
}

export default LowerFooter