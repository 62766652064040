import React, { useContext, useState } from "react";
import { StyledButton, StyledNavbar, StyledNavlink } from "./Navbar.styles";
import { MdLightMode } from "react-icons/md";
import { FiMoon } from "react-icons/fi";
import { ROUTES } from "../../Constants/Routes";
import { useTheme } from "styled-components";
import { ThemeContext } from "../../App";
import CustomDrawer from "../CustomDrawer/CustomDrawer";
import { IoReorderThreeOutline } from "react-icons/io5";
import AuditModal from "../AuditModal/AuditModal";

const Navbar = () => {
  const themeContext = useContext(ThemeContext);
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState();
  const [openModal, setOpenModal] = useState(false);
  const onChange = () => {
    if (themeContext?.currentTheme === "light") {
      themeContext?.setCurrentTheme("dark");
      localStorage.setItem("currentTheme", "dark");
    } else {
      themeContext?.setCurrentTheme("light");
      localStorage.setItem("currentTheme", "light");
    }
  };
  console.log(themeContext, "see theme");
  return (
    <StyledNavbar>
      <div className="left">
        {themeContext?.currentTheme === "light" ? (
          <img src="/Images/Logo.svg" />
        ) : (
          <img src="/Images/LogoDark.png" />
        )}
      </div>
      <div className="right">
        <div className="main-menu">
        <div className="navlinks">
          <StyledNavlink to={ROUTES.HOME}>HOME</StyledNavlink>
          <StyledNavlink to={ROUTES.ABOUT}>ABOUT</StyledNavlink>
          <StyledNavlink to={ROUTES.CLIENTS}>CLIENTS</StyledNavlink>
          <StyledNavlink to={ROUTES.SERVICES}>SERVICES</StyledNavlink>
          <div style={{ cursor: "pointer" }} onClick={onChange}>
            {themeContext?.currentTheme === "light" ? (
              <FiMoon size={20} strokeWidth={1} color={theme.blue.navyBlue} />
            ) : (
              <MdLightMode size={20} color={theme.black} />
            )}
          </div>
        </div>

        <StyledButton onClick={()=>setOpenModal(true)}>Book an Audit</StyledButton>

        </div>
        
        <div className="drawer-icon">
        <IoReorderThreeOutline
          style={{ cursor: "pointer" }}
          color={theme.black}
          onClick={()=>setOpenDrawer(true)}
          size={30}
        />
      </div>
      <CustomDrawer  placement="top" openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
      <AuditModal openModal={openModal} setOpenModal={setOpenModal}/>
      </div>
     
    </StyledNavbar>
  );
};

export default Navbar;
