import styled from "styled-components";

export const StyledWorkCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  height: 400px;
  border-radius: 16px;
  border: 1px solid rgba(70, 130, 169, 0.29);
  position: relative;
  background: #fff;

  box-shadow: -2px 5px 12px 0px rgba(70, 130, 169, 0.1),
    -7px 20px 21px 0px rgba(70, 130, 169, 0.09),
    -15px 45px 29px 0px rgba(70, 130, 169, 0.05),
    -26px 80px 34px 0px rgba(70, 130, 169, 0.01),
    -41px 126px 37px 0px rgba(70, 130, 169, 0);

  .left-image {
    position: absolute;
    top: 100px;
    left: -50px;
    width: 110px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-image {
    position: absolute;
    right: -75px;
    bottom: -127px;
    width: 180px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .welcome {
    color: #394955;

    font-family: Exo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 10px 0 10px;
  }
  .ant-divider-horizontal {
    margin: 12px 0 !important;
  }

  .para-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      color: #000;

      font-family: Exo;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      padding: 0;
      margin: 0;
    }
    p {
      color: rgba(57, 73, 85, 0.7);

      text-align: center;
      font-family: Exo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }
  }
  .list-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;

    .lists {
      display: flex;
      justify-content: space-between;
      background: rgba(246, 244, 235, 0.5);
      border-radius: 8px;
      align-items: center;
      gap: 30px;
      padding: 10px;

      span {
        color: #394955;
        font-family: Exo;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  @media(max-width: 600px) {
  .left-image{
    display: none;
  }
  .right-image{
    display: none;
  }
}
`;
