import styled from "styled-components";

export const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${(props) => props.theme.backgroundTrust};
  padding: 20px 70px 0px 70px;
  color: ${(props) => props.theme.grey};
  flex-wrap: wrap;

  font-family: Exo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  .left {
    width: 400px;
    .socials {
      display: flex;
      gap: 15px;
      /* width: 60%; */
      height: 48px;
    }
  }
  .right {
    width: 400px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .container {
      display: flex;
      flex-direction: column;
      align-items: end;
      /* width: 150px; */
    }
    p {
      color: ${(props) => props.theme.blue.servicePara};
      font-size: 18px;
      font-weight: 700;
    }
    .list {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 8px;
      span {
        font-size: 14px;
        color: ${(props) => props.theme.grey};
      }
    }
  }
  @media(max-width: 800px) {
    display: flex;
    justify-content: right;
  
    .left{
      text-align: right;
      .socials{
        justify-content: end;
      }
    }
    .right{
      grid-template-columns: repeat(1, 1fr);
     
      

    }
    .container {
      
      text-align: left;
      
    }
  }
  @media(max-width: 400px) {
    padding: 20px 20px 0px 20px;
  }
`;
