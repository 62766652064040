import React from 'react'
import { StyledClients } from './Clients.styles'
import { BackgroundContainer, GlobalContainer } from '../../GlobalStyles'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import LowerFooter from '../../Components/LowerFooter/LowerFooter'
import ClientCards from './Components/ClientCards/ClientCards'
import StudiesCard from './Components/StudiesCard/StudiesCard'
import { useGetCmsClientsDataQuery } from '../../Services/Cms'

const Clients = () => {
  const {data : getCmsClientData} = useGetCmsClientsDataQuery();
  return (
    <BackgroundContainer>

    <Navbar/>
    <GlobalContainer>
    <StyledClients>
        <div className="clients-container">
            <h3>My Previous Clients</h3>
            <p>{getCmsClientData?.data?.attributes?.heading}</p>
        </div>
        <div className="card-container">
            <ClientCards url="/Images/Clients/Client1.png" clientOne={getCmsClientData?.data?.attributes?.ClientOne}/>
            <ClientCards url="/Images/Clients/Client2.png" clientTwo={getCmsClientData?.data?.attributes?.ClientTwo}/>
            <ClientCards url="/Images/Clients/Client3.png" clientThree={getCmsClientData?.data?.attributes?.ClientThree}/>
            <ClientCards url="/Images/Clients/Client4.png" clientFour={getCmsClientData?.data?.attributes?.ClientFour}/>

        </div>
        <div className="case-studies">
        <div className='studies-head'>
              <span>Case Studies</span>
              <span className='offer'>Check out the projects I worked on.</span>
        </div>
        <div className="studies-card-container">
          <StudiesCard image="/Images/Clients/Studies1.png" project="Project 1" number="23"/>
          <StudiesCard image="/Images/Clients/Studies2.png" project="Project 1" number="23"/>
          <StudiesCard image="/Images/Clients/Studies3.png" project="Project 1" number="23"/>
          <StudiesCard image="/Images/Clients/Studies4.png" project="Project 1" number="23"/>
          <StudiesCard image="/Images/Clients/Studies5.png" project="Project 1" number="23"/>
          <StudiesCard image="/Images/Clients/Studies6.png" project="Project 1" number="23"/>

        </div>
        </div>
        
    </StyledClients>
    </GlobalContainer>
    <div>
        <Footer/>
        <LowerFooter/>
    </div>
    </BackgroundContainer>



  )
}

export default Clients