import styled from "styled-components";

export const StyledLogos = styled.div`

.marquee{
    gap: 100px;
    display: flex;
width: 100%;
gap: 100px;

}
`