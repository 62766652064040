import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes/AppRoutes';
import { ThemeProvider } from 'styled-components';
import { createContext, useEffect, useMemo, useState } from 'react';
import { theme } from './Theme';
import { Toaster } from 'react-hot-toast';

export const ThemeContext = createContext();


function App() {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("currentTheme")
  );
  useEffect(() => {
      if (!localStorage.getItem("currentTheme")) {
        localStorage.setItem("currentTheme", "light");
        setCurrentTheme("light");
      }
      
    }, [currentTheme]);
    const themeContextValue = useMemo(
      () => ({ currentTheme, setCurrentTheme }),
      [currentTheme, setCurrentTheme])
  
  return (
    <>
    <ThemeContext.Provider value={themeContextValue}>
    <ThemeProvider theme={currentTheme === "light" ? theme.light : theme.dark}>

    <AppRoutes/>
    <Toaster />


    </ThemeProvider>
    </ThemeContext.Provider>
    </>
  );
}

export default App;
