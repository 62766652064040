import { Modal } from "antd";
import styled from "styled-components";

export const StyledAuditModal = styled(Modal)`
.ant-modal-content{
    background: ${(props)=>props.theme.white.plainWhite};
}

        label {
            color: rgba(54, 60, 79, 0.7);
            font-family: Exo;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 102.523%; /* 18.454px */
          }
          input {
            border-radius: 8px;
            border: 1px solid rgba(54, 60, 79, 0.2);
            background: ${(props)=>props.theme.white.serviceCardBackground};
            height: 46px;
            color: ${(props)=>props.theme.blue.navyBlue};
          }
          textarea {
            min-height: 100px;
            background: ${(props)=>props.theme.white.serviceCardBackground};
            color: ${(props)=>props.theme.blue.navyBlue};
            /* border: none; */
            border: 1px solid rgba(54, 60, 79, 0.2);
          }
          input::placeholder,
          textarea::placeholder {
            color: ${(props)=>props.theme.blue.navyBlue};
            font-family: Exo;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 102.523%; /* 20.505px */
          }
          .btn-container{
            display: flex;
            justify-content: end;
            gap: 10px;
          }
          .save-btn{
            background: ${(props)=>props.theme.blue.lightBlue} !important;
            width: 100px!important;
            height: 40px!important;
            span{
                font-size: 18px!important;
                color: white !important;
            }
          }
          /* .ant-btn-primary {
            background: ${(props)=>props.theme.blue.lightBlue};
            width: 100px;
            height: 40px;
            span{
                font-size: 18px;
            }
          } */
          .ant-btn-default{
            width: 100px;
            height: 40px;
            background: transparent;
            border: 1px solid ${(props)=>props.theme.blue.lightBlue};
            span{
                font-size: 18px;
                color: ${(props)=>props.theme.blue.lightBlue};
            }
          }
        

`