import { Button } from "antd";
import styled from "styled-components";

export const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 100px;

  .main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 100px;
    /* @media only screen and (max-width: 1000px) {
    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    
  } */
    .left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-left: 30px;
      h1 {
        color: ${(props) => props.theme.blue.headBlue};

        font-family: Exo;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
      .btns-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    .right {
      display: flex;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .services-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      color: ${(props) => props.theme.blue.headBlue};

      font-family: Exo;
      font-size: 62px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    .service-head {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      align-items: baseline;
      span {
        color: ${(props) => props.theme.blue.headBlue};
        font-family: Exo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }
      .offer {
        color: ${(props) => props.theme.blue.lightBlue}!important;
        font-size: 18px !important;
        font-weight: 500;
      }
    }
    .service-card {
      /* display: flex;
      justify-content: space-between; */
      display: grid;
      grid-template-columns: repeat(4,1fr);
      
      gap: 15px;

    }
  }
  @media(max-width: 1000px) {
    .main-container{

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        
    }
    .right{
        order: -1;

    }
    .service-card{
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
@media(max-width: 600px) {
  h1{
    font-size: 54px !important;
  }
    
    .service-card{
        display: grid !important;
        grid-template-columns: repeat(1, 1fr) !important;
    }
}
`;
export const StyledBorderButton = styled(Button)`
  border-radius: 87px;
  height: 50px;
  background: transparent;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.blue.lightBlue};
  span {
    color: ${(props) => props.theme.blue.lightBlue};
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 20px;
  }
  
`;
