import React, { useState } from "react";
import { StyledAuditModal } from "./AuditModal.styles";
import { Button, Form, Input, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { BackgroundContainer } from "../../GlobalStyles";
import { PlusOutlined } from "@ant-design/icons";
import { useTheme } from "styled-components";
import {
  useSendFormDataMutation,
  useUploadImageMutation,
} from "../../Services/Auth";
import { useForm } from "antd/es/form/Form";
import toast from "react-hot-toast";

const AuditModal = ({ openModal, setOpenModal }) => {
  const theme = useTheme();

  const [auditForm] = useForm();
  const [sendFormData, {isLoading, }] = useSendFormDataMutation();
  const [fileList, setFileList] = useState([]);
  const [uploadImage] = useUploadImageMutation();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const upload = async (file) => {
    return Promise.resolve("aaa");
  };

  const customRequest = async (options) => {
    options.onProgress({ percent: 0 });
    const url = await upload();
    options.onProgress({ percent: 100 });
    options.onSuccess();
    // if (onChange) onChange(url);
  };

  const submitForm = async (values) => {
    console.log(values, "values");
    const formData = new FormData();
    // formData.append("image", fileList?.[0]);
    try {
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('message', values.message);
        formData.append('image', fileList?.[0]?.originFileObj);
      const response = await sendFormData(formData).unwrap();
      console.log(response, "response");
      setOpenModal(false);
      toast.success("Audit Booked");
      auditForm.resetFields();
      setFileList([]);
      
    } catch (error) {
      console.log(error, "error");
      toast.error("Error");
    }
  };
  const onUploadChange = (info) => {
    console.log("onUploadChange: ", info);
    setFileList([...info.fileList]);
  };

  console.log("fileList: ", fileList);
  const onClose = ()=>{
    setOpenModal(false);
    auditForm.resetFields();
    setFileList([]);
  }
  const onCancel = ()=>{
    setOpenModal(false);
    auditForm.resetFields();
    setFileList([]);
  }
  return (
    <BackgroundContainer>
      <StyledAuditModal
        open={openModal}
        okText={"Submit"}
        onCancel={() => setOpenModal(false)}
        // onOk={submitForm}
        footer={null}
      >
        <div className="form-container">
          <Form
            form={auditForm}
            onFinish={submitForm}
            onClose={onClose}
          >
            <div className="form-row-container">
              <div className="field-container">
                <label>Full Name</label>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Full Name" />
                </Form.Item>
              </div>
              <div className="field-container">
                <label>Email Address</label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Must be valid Email",
                    },
                  ]}
                >
                  <Input placeholder="Enter email address" />
                </Form.Item>
              </div>
            </div>
            <div className="form-row-container">
              <div className="field-container full">
                <label>Message</label>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea placeholder="Enter Your Message" />
                </Form.Item>
              </div>
            </div>
            <div className="field-container">
              <label>Upload Image</label>
              {/* <Form.Item valuePropName="fileList" getValueFromEvent={normFile}> */}
              <Form.Item name="image">

              
              <Upload
                customRequest={customRequest}
                //   customRequest={upload}
                onChange={onUploadChange}
                fileList={fileList}
                listType="picture-card"
                multiple={false}
                disabled={fileList.length>0}
              >
                <div>
                  <PlusOutlined
                    style={{
                      color: theme.blue.navyBlue,
                    }}
                  />
                  <div
                    style={{
                      marginTop: 8,
                      color: theme.blue.navyBlue,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
              </Form.Item>
            </div>
            <div className="btn-container">
              <Button onClick={onCancel}>Cancel</Button>
              <Button className="save-btn" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </StyledAuditModal>
    </BackgroundContainer>
  );
};

export default AuditModal;
