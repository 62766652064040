import React, { useContext } from "react";
import { StyledFooter } from "./Footer.styles";
import { ThemeContext } from "../../App";
import { useTheme } from "styled-components";
import { useGetCmsFooterDataQuery } from "../../Services/Cms";

const Footer = () => {
  const themeContext = useContext(ThemeContext);
  const { data: getCmsFooterData}= useGetCmsFooterDataQuery();

  return (
    <StyledFooter>
      <div className="left">
        {themeContext?.currentTheme === "light" ? (
          <img src="/Images/Logo.svg" />
        ) : (
          <img src="/Images/LogoDark.png" />
        )}
        <p>
          {getCmsFooterData?.data?.attributes?.text}
        </p>
        <div className="socials">
          <img src="/Images/Socials/Discord.png" alt="" />
          <img src="/Images/Socials/Twitter.svg" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="container">
          <p>Services</p>
          <div className="list">
            <span>Initial Server Setup</span>
            <span>Bot Configuration</span>
            <span>Airtight Security</span>
            <span>Audit & Feedback Framework</span>
          </div>
        </div>
        <div className="container">
          <p>Information</p>
          <div className="list">
            <span>Who am i ?</span>
            <span>How i can help</span>
            <span>Pricing</span>
            <span>Contact Me</span>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
