import React from 'react'
import { StyledServiceCard } from './ServiceCard.styles'

const ServiceCard = ({url, title}) => {
  return (
    <StyledServiceCard>
        <div className="image">

        <img src={url} alt="" />
        </div>
        <p>{title}</p>

    </StyledServiceCard>
  )
}

export default ServiceCard