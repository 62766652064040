import { Drawer } from "antd";
import styled from "styled-components";

export const StyledCustomDrawer = styled(Drawer)`
 & .ant-drawer-body {
    background: ${(props) => props.theme.white.plainWhite} !important;
    display: flex;
  flex-direction: column;
  gap: 20px;
    .ant-menu {
      background: ${(props) => props.theme.white.plainWhite} !important;
    }
  }
  .ant-drawer-header{
    background: ${(props) => props.theme.white.plainWhite} !important;
    border: none;
  }
  display: flex;
  flex-direction: column;

  .ant-drawer-title{
    color: ${(props) => props.theme.black} !important;
  }
  .ant-drawer-close{
    color: ${(props) => props.theme.black} !important;
  }
  

`