import React from "react";
import { Route, Routes } from "react-router";
import { ROUTES } from "../Constants/Routes";
import Home from "../Screens/Home/Home";
import About from "../Screens/About/About";
import Clients from "../Screens/Clients/Clients";
import Services from "../Screens/Services/Services";
import Contact from "../Screens/Contact/Contact";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.CLIENTS} element={<Clients />} />
        <Route path={ROUTES.SERVICES} element={<Services />} />"
        <Route path={ROUTES.CONTACTS} element={<Contact />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
