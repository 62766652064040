import React, { useContext, useState } from 'react'
import { StyledBorderButton, StyledHome } from './Home.styles'
import { ThemeContext } from '../../App';
import Navbar from "../../Components/Navbar/Navbar"
import { StyledButton } from '../../Components/Navbar/Navbar.styles';
import { BackgroundContainer, GlobalContainer } from '../../GlobalStyles';
import ServiceCard from './Components/ServiceCard/ServiceCard';
import Trusted from './Components/Trusted/Trusted';
import HowItWorks from './Components/HowItWorks/HowItWorks';
import Footer from '../../Components/Footer/Footer';
import LowerFooter from '../../Components/LowerFooter/LowerFooter';
import { useGetCmsLandingDataQuery } from '../../Services/Cms';
import AuditModal from '../../Components/AuditModal/AuditModal';

const Home = () => {
    const { setCurrentTheme, currentTheme } = useContext(ThemeContext);
    const [openModal, setOpenModal] = useState();
    const {data: getCmsLandingData} = useGetCmsLandingDataQuery();
    console.log(getCmsLandingData, "response");
  return (
  <BackgroundContainer>
        <Navbar />
        <StyledHome>
        <GlobalContainer>
        <div className="main-container">
          <div className="left">
            <h1>Make Discord server safe.</h1>
            <div className="btns-container">
              <StyledButton onClick={()=>setOpenModal(true)}>Book an Audit</StyledButton>
              <StyledBorderButton>Secure your Discord today.</StyledBorderButton>

            </div>
            <AuditModal openModal={openModal} setOpenModal={setOpenModal}/>

          </div>
          <div className="right">
            <img src="/Images/Check.png"/>
            
          </div>
        </div>
        <div className="services-container">
        <div className='service-head'>
              <span >Services</span>
              <span className='offer'>What do I offer?</span>
            </div>
            <div className="service-card">
              <ServiceCard url={'Images/Service/Service1.png'} title={'Server Configuration'}/>
              <ServiceCard url={'Images/Service/Service2.png'} title={'Bots Configuration'}/>
              <ServiceCard url={'Images/Service/Service3.png'} title={'Airtight Security'}/>
              <ServiceCard url={'Images/Service/Service4.png'} title={'Audit & feedback'}/>
            </div>

        </div>
          </GlobalContainer>  
        <Trusted/>
        <GlobalContainer>

        <HowItWorks/>
        </GlobalContainer>

      
    </StyledHome>

          <div>

          <Footer/>
          <LowerFooter/>
          </div>
    
        </BackgroundContainer>


  )
}

export default Home