import React, { useState } from "react";
import { StyledStepper } from "./Stepper.styles";

const Stepper = () => {
  return (
    <StyledStepper
      items={[
        {
          title: "Initial Server Setup",
          status: "finish",
          icon: <img src="/Images/Step.png" height="100%" />,
        },
        {
          title: "Bot Configuration",
          status: "wait",
          icon: <img src="/Images/Step2.png" height="100%" />,
        },
        {
          title: "Audit & Feedback Framework",
          status: "wait",
          icon: <img src="/Images/Step3.png" height="100%" />,
        },
        {
          title: "Airtight Security",
          status: "wait",
          icon: <img src="/Images/Step4.png" height="100%" />,
        },
      ]}
    ></StyledStepper>
  );
};

export default Stepper;
