import { Button } from "antd";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(70, 130, 169, 0.17);
  padding: 20px 50px 20px 50px;
  background: ${(props) => props.theme.white.plainWhite};

  .left,
  .navlinks {
    display: flex !important;
    align-items: center;
    gap: 30px;
  }
  .right {
    .main-menu {
      display: flex ;
      align-items: center;
      gap: 30px;
    }
  }
  .drawer-icon {
    display: none;
  }
  @media (max-width: 1000px) {
    .main-menu {
      display: none !important;
      /* visibility: hidden; */
    }
    .drawer-icon {
      display: block;
      text-align: end;
    }
  }
`;

export const StyledNavlink = styled(NavLink)`
  text-decoration: none;
  /* color: #275DFD; */
  color: ${(props) => props.theme.blue.navyBlue};

  font-family: Exo;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.52px;

  &.active {
    color: #275dfd;
  }
`;
export const StyledButton = styled(Button)`
  border-radius: 87px;
  height: 50px;
  background: ${(props) => props.theme.blue.lightBlue};
  justify-content: center;
  align-items: center;
  border: none;
  span {
    color: ${(props) => props.theme.white.fadeWhite};
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 5px 20px;
  }
`;
