import styled from "styled-components";

export const StyledContactContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 100px;
  margin-bottom: 100px;
  margin-top: 50px;
  align-items: center;
  .right {
    .card {
      color: ${(props)=>props.theme.white.plainWhite};
      border-radius: 40px;
      padding: 10px 20px;
      background: #285ef9;
      .prev-work-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .view-all-btn {
          height: 50px;
          border-radius: 87px;
          margin-bottom: 40px;
          background: #fff;
          margin-top: 60px;
          width: 25%;
          @media (max-width: 1000px) {
            width: 40%;
            border-radius: 20px;
            height: 40px;
    
}
         
          @media (max-width: 800px) {
            width: 30%;
            border-radius: 20px;
            height: 35px;
    
}

          span {
            color: #275dfd;
            font-family: Exo;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media (max-width: 1000px) {
            font-size: 16px !important;
    
}
          
            
            @media (max-width: 800px) {
            font-size: 16px !important;
    
}
          }
        }
      }

      .title {
        color: white;
        text-align: center;
        font-family: Exo;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 48px */
      }
    }
  }
  .form-container {
    
    .save-btn {
      border-radius: 87px;
      height: 60px;
      background: #275dfd;
      width: 20%;
      border: none;
      span {
        color: #ebedec;
        font-family: Exo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .title {
      color: ${(props)=>props.theme.blue.servicePara};
      font-family: Exo;
      font-size: 40px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      line-height: 102.523%; /* 49.211px */
    }
    .desc {
      color: #285ef9;
      font-family: Exo;
      margin-top: 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 102.523%; /* 24.605px */
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .form-row-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .field-container {
          width: 48%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          gap: 10px;
          label {
            color: rgba(54, 60, 79, 0.7);
            font-family: Exo;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 102.523%; /* 18.454px */
          }
          input {
            border-radius: 8px;
            border: 1px solid rgba(54, 60, 79, 0.2);
            background: ${(props)=>props.theme.white.serviceCardBackground};
            height: 46px;
            color: ${(props)=>props.theme.blue.navyBlue};
          }
          textarea {
            min-height: 100px;
            background: ${(props)=>props.theme.white.serviceCardBackground};
            color: ${(props)=>props.theme.blue.navyBlue};
            /* border: none; */
            border: 1px solid rgba(54, 60, 79, 0.2);
          }
          input::placeholder,
          textarea::placeholder {
            color: ${(props)=>props.theme.blue.navyBlue};
            font-family: Exo;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 102.523%; /* 20.505px */
          }
        }
        .field-container.full {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    .form-container{
      .title{
        font-size: 32px;
      }
      .desc{
        font-size: 18px;
      }
      .save-btn{
        border-radius: 50px;
    height: 40px;
    width: 45%;
    margin: auto;
      }
    }
    
}
@media (max-width: 1000px) {
    
      .save-btn{
        border-radius: 50px;
    height: 38px;
    width: 55% !important;
    margin: auto;
    border-radius: 30px!important;
    span{
      font-size: 16px !important;
    }
      }
    }
`;
