import styled from "styled-components";

export const StyledClientCards = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
border: 1px solid rgba(57, 73, 85, 0.14);
gap: 5px;
min-width: 200px;
width: 240px;
/* width: 100%; */
min-height: 325px;

background: ${(props)=>props.theme.white.serviceCardBackground};
padding: 10px;

box-shadow: -1px 1px 3px 0px rgba(61, 84, 227, 0.10), -2px 5px 5px 0px rgba(61, 84, 227, 0.09), -5px 11px 7px 0px rgba(61, 84, 227, 0.05), -9px 19px 9px 0px rgba(61, 84, 227, 0.01), -14px 30px 9px 0px rgba(61, 84, 227, 0.00);
    /* width: 250px;
height: 250px; */
img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
p{
    color: ${(props)=>props.theme.blue.lightBlue};
font-style: normal;
font-weight: 700;
line-height: normal;
font-family: Open Sans;
font-size: 22px;
margin: 0;
}
span{
    color:${(props)=>props.theme.blue.headBlue};

font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
padding-bottom: 40px;
}

`